// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file activity/v1/activity_instance.proto (package activity.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { DoubleValue, Duration, Message, proto3 } from "@bufbuild/protobuf";
import { MeterDouble } from "../../uom/v1/length_pb.js";
import { ExerciseSet } from "./exercise_set_pb.js";

/**
 * @generated from message activity.v1.ActivityInstance
 */
export class ActivityInstance extends Message<ActivityInstance> {
  /**
   * @generated from oneof activity.v1.ActivityInstance.kind
   */
  kind: {
    /**
     * @generated from field: activity.v1.ActivityInstanceExercise exercise = 1;
     */
    value: ActivityInstanceExercise;
    case: "exercise";
  } | {
    /**
     * @generated from field: activity.v1.ActivityInstanceMuscles muscles = 2;
     */
    value: ActivityInstanceMuscles;
    case: "muscles";
  } | {
    /**
     * @generated from field: activity.v1.ActivityInstanceJob job = 3;
     */
    value: ActivityInstanceJob;
    case: "job";
  } | {
    /**
     * @generated from field: activity.v1.ActivityInstanceSleep sleep = 4;
     */
    value: ActivityInstanceSleep;
    case: "sleep";
  } | {
    /**
     * @generated from field: activity.v1.ActivityInstanceGroup group = 5;
     */
    value: ActivityInstanceGroup;
    case: "group";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ActivityInstance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityInstance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "exercise", kind: "message", T: ActivityInstanceExercise, oneof: "kind" },
    { no: 2, name: "muscles", kind: "message", T: ActivityInstanceMuscles, oneof: "kind" },
    { no: 3, name: "job", kind: "message", T: ActivityInstanceJob, oneof: "kind" },
    { no: 4, name: "sleep", kind: "message", T: ActivityInstanceSleep, oneof: "kind" },
    { no: 5, name: "group", kind: "message", T: ActivityInstanceGroup, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityInstance {
    return new ActivityInstance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityInstance {
    return new ActivityInstance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityInstance {
    return new ActivityInstance().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityInstance | PlainMessage<ActivityInstance> | undefined, b: ActivityInstance | PlainMessage<ActivityInstance> | undefined): boolean {
    return proto3.util.equals(ActivityInstance, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityInstanceExercise
 */
export class ActivityInstanceExercise extends Message<ActivityInstanceExercise> {
  /**
   * @generated from field: string activity_id = 2;
   */
  activityId = "";

  /**
   * @generated from field: optional google.protobuf.Duration duration = 3;
   */
  duration?: Duration;

  /**
   * @generated from field: uom.v1.MeterDouble distance = 4;
   */
  distance?: MeterDouble;

  /**
   * @generated from field: google.protobuf.DoubleValue intensity = 5;
   */
  intensity?: number;

  /**
   * @generated from field: repeated activity.v1.ExerciseSet sets = 6;
   */
  sets: ExerciseSet[] = [];

  /**
   * @generated from field: google.protobuf.DoubleValue alignment = 7;
   */
  alignment?: number;

  constructor(data?: PartialMessage<ActivityInstanceExercise>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityInstanceExercise";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "activity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "duration", kind: "message", T: Duration, opt: true },
    { no: 4, name: "distance", kind: "message", T: MeterDouble },
    { no: 5, name: "intensity", kind: "message", T: DoubleValue },
    { no: 6, name: "sets", kind: "message", T: ExerciseSet, repeated: true },
    { no: 7, name: "alignment", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityInstanceExercise {
    return new ActivityInstanceExercise().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityInstanceExercise {
    return new ActivityInstanceExercise().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityInstanceExercise {
    return new ActivityInstanceExercise().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityInstanceExercise | PlainMessage<ActivityInstanceExercise> | undefined, b: ActivityInstanceExercise | PlainMessage<ActivityInstanceExercise> | undefined): boolean {
    return proto3.util.equals(ActivityInstanceExercise, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityInstanceMuscles
 */
export class ActivityInstanceMuscles extends Message<ActivityInstanceMuscles> {
  /**
   * @generated from field: string activity_id = 2;
   */
  activityId = "";

  /**
   * @generated from field: repeated string muscle_group_ids = 3;
   */
  muscleGroupIds: string[] = [];

  /**
   * @generated from field: optional google.protobuf.Duration duration = 4;
   */
  duration?: Duration;

  /**
   * @generated from field: google.protobuf.DoubleValue intensity = 5;
   */
  intensity?: number;

  /**
   * @generated from field: google.protobuf.DoubleValue alignment = 6;
   */
  alignment?: number;

  constructor(data?: PartialMessage<ActivityInstanceMuscles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityInstanceMuscles";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "activity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "muscle_group_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "duration", kind: "message", T: Duration, opt: true },
    { no: 5, name: "intensity", kind: "message", T: DoubleValue },
    { no: 6, name: "alignment", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityInstanceMuscles {
    return new ActivityInstanceMuscles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityInstanceMuscles {
    return new ActivityInstanceMuscles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityInstanceMuscles {
    return new ActivityInstanceMuscles().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityInstanceMuscles | PlainMessage<ActivityInstanceMuscles> | undefined, b: ActivityInstanceMuscles | PlainMessage<ActivityInstanceMuscles> | undefined): boolean {
    return proto3.util.equals(ActivityInstanceMuscles, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityInstanceJob
 */
export class ActivityInstanceJob extends Message<ActivityInstanceJob> {
  /**
   * @generated from field: string activity_id = 2;
   */
  activityId = "";

  /**
   * @generated from field: google.protobuf.Duration duration = 3;
   */
  duration?: Duration;

  constructor(data?: PartialMessage<ActivityInstanceJob>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityInstanceJob";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "activity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "duration", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityInstanceJob {
    return new ActivityInstanceJob().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityInstanceJob {
    return new ActivityInstanceJob().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityInstanceJob {
    return new ActivityInstanceJob().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityInstanceJob | PlainMessage<ActivityInstanceJob> | undefined, b: ActivityInstanceJob | PlainMessage<ActivityInstanceJob> | undefined): boolean {
    return proto3.util.equals(ActivityInstanceJob, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityInstanceSleep
 */
export class ActivityInstanceSleep extends Message<ActivityInstanceSleep> {
  /**
   * @generated from field: string activity_id = 2;
   */
  activityId = "";

  /**
   * @generated from field: google.protobuf.Duration duration = 3;
   */
  duration?: Duration;

  constructor(data?: PartialMessage<ActivityInstanceSleep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityInstanceSleep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "activity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "duration", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityInstanceSleep {
    return new ActivityInstanceSleep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityInstanceSleep {
    return new ActivityInstanceSleep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityInstanceSleep {
    return new ActivityInstanceSleep().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityInstanceSleep | PlainMessage<ActivityInstanceSleep> | undefined, b: ActivityInstanceSleep | PlainMessage<ActivityInstanceSleep> | undefined): boolean {
    return proto3.util.equals(ActivityInstanceSleep, a, b);
  }
}

/**
 * @generated from message activity.v1.ActivityInstanceGroup
 */
export class ActivityInstanceGroup extends Message<ActivityInstanceGroup> {
  /**
   * @generated from field: repeated activity.v1.ActivityInstance activities = 2;
   */
  activities: ActivityInstance[] = [];

  /**
   * @generated from field: int32 repetitions = 3;
   */
  repetitions = 0;

  /**
   * @generated from field: google.protobuf.Duration break_between_repetitions = 4;
   */
  breakBetweenRepetitions?: Duration;

  constructor(data?: PartialMessage<ActivityInstanceGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "activity.v1.ActivityInstanceGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "activities", kind: "message", T: ActivityInstance, repeated: true },
    { no: 3, name: "repetitions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "break_between_repetitions", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivityInstanceGroup {
    return new ActivityInstanceGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivityInstanceGroup {
    return new ActivityInstanceGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivityInstanceGroup {
    return new ActivityInstanceGroup().fromJsonString(jsonString, options);
  }

  static equals(a: ActivityInstanceGroup | PlainMessage<ActivityInstanceGroup> | undefined, b: ActivityInstanceGroup | PlainMessage<ActivityInstanceGroup> | undefined): boolean {
    return proto3.util.equals(ActivityInstanceGroup, a, b);
  }
}


// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file uom/v1/mass.proto (package uom.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum uom.v1.UnitMass
 */
export enum UnitMass {
  /**
   * @generated from enum value: UNIT_MASS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * SI base unit
   *
   * @generated from enum value: UNIT_MASS_KILOGRAM = 1;
   */
  KILOGRAM = 1,

  /**
   * @generated from enum value: UNIT_MASS_GRAM = 2;
   */
  GRAM = 2,

  /**
   * @generated from enum value: UNIT_MASS_MILLIGRAM = 3;
   */
  MILLIGRAM = 3,

  /**
   * @generated from enum value: UNIT_MASS_POUND = 4;
   */
  POUND = 4,

  /**
   * @generated from enum value: UNIT_MASS_OUNCE = 5;
   */
  OUNCE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(UnitMass)
proto3.util.setEnumType(UnitMass, "uom.v1.UnitMass", [
  { no: 0, name: "UNIT_MASS_UNSPECIFIED" },
  { no: 1, name: "UNIT_MASS_KILOGRAM" },
  { no: 2, name: "UNIT_MASS_GRAM" },
  { no: 3, name: "UNIT_MASS_MILLIGRAM" },
  { no: 4, name: "UNIT_MASS_POUND" },
  { no: 5, name: "UNIT_MASS_OUNCE" },
]);

/**
 * @generated from message uom.v1.MassInt32
 */
export class MassInt32 extends Message<MassInt32> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: int32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassInt32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassInt32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassInt32 {
    return new MassInt32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassInt32 {
    return new MassInt32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassInt32 {
    return new MassInt32().fromJsonString(jsonString, options);
  }

  static equals(a: MassInt32 | PlainMessage<MassInt32> | undefined, b: MassInt32 | PlainMessage<MassInt32> | undefined): boolean {
    return proto3.util.equals(MassInt32, a, b);
  }
}

/**
 * @generated from message uom.v1.MassInt64
 */
export class MassInt64 extends Message<MassInt64> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: int64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<MassInt64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassInt64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassInt64 {
    return new MassInt64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassInt64 {
    return new MassInt64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassInt64 {
    return new MassInt64().fromJsonString(jsonString, options);
  }

  static equals(a: MassInt64 | PlainMessage<MassInt64> | undefined, b: MassInt64 | PlainMessage<MassInt64> | undefined): boolean {
    return proto3.util.equals(MassInt64, a, b);
  }
}

/**
 * @generated from message uom.v1.MassUint32
 */
export class MassUint32 extends Message<MassUint32> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: uint32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassUint32>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassUint32";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassUint32 {
    return new MassUint32().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassUint32 {
    return new MassUint32().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassUint32 {
    return new MassUint32().fromJsonString(jsonString, options);
  }

  static equals(a: MassUint32 | PlainMessage<MassUint32> | undefined, b: MassUint32 | PlainMessage<MassUint32> | undefined): boolean {
    return proto3.util.equals(MassUint32, a, b);
  }
}

/**
 * @generated from message uom.v1.MassUint64
 */
export class MassUint64 extends Message<MassUint64> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: uint64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<MassUint64>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassUint64";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassUint64 {
    return new MassUint64().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassUint64 {
    return new MassUint64().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassUint64 {
    return new MassUint64().fromJsonString(jsonString, options);
  }

  static equals(a: MassUint64 | PlainMessage<MassUint64> | undefined, b: MassUint64 | PlainMessage<MassUint64> | undefined): boolean {
    return proto3.util.equals(MassUint64, a, b);
  }
}

/**
 * @generated from message uom.v1.MassFloat
 */
export class MassFloat extends Message<MassFloat> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassFloat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassFloat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassFloat {
    return new MassFloat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassFloat {
    return new MassFloat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassFloat {
    return new MassFloat().fromJsonString(jsonString, options);
  }

  static equals(a: MassFloat | PlainMessage<MassFloat> | undefined, b: MassFloat | PlainMessage<MassFloat> | undefined): boolean {
    return proto3.util.equals(MassFloat, a, b);
  }
}

/**
 * @generated from message uom.v1.MassDouble
 */
export class MassDouble extends Message<MassDouble> {
  /**
   * @generated from field: uom.v1.UnitMass unit = 1;
   */
  unit = UnitMass.UNSPECIFIED;

  /**
   * @generated from field: double value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<MassDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MassDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unit", kind: "enum", T: proto3.getEnumType(UnitMass) },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MassDouble {
    return new MassDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MassDouble {
    return new MassDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MassDouble {
    return new MassDouble().fromJsonString(jsonString, options);
  }

  static equals(a: MassDouble | PlainMessage<MassDouble> | undefined, b: MassDouble | PlainMessage<MassDouble> | undefined): boolean {
    return proto3.util.equals(MassDouble, a, b);
  }
}

/**
 * @generated from message uom.v1.KilogramDouble
 */
export class KilogramDouble extends Message<KilogramDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<KilogramDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.KilogramDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KilogramDouble {
    return new KilogramDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KilogramDouble {
    return new KilogramDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KilogramDouble {
    return new KilogramDouble().fromJsonString(jsonString, options);
  }

  static equals(a: KilogramDouble | PlainMessage<KilogramDouble> | undefined, b: KilogramDouble | PlainMessage<KilogramDouble> | undefined): boolean {
    return proto3.util.equals(KilogramDouble, a, b);
  }
}

/**
 * @generated from message uom.v1.GramDouble
 */
export class GramDouble extends Message<GramDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<GramDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.GramDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GramDouble {
    return new GramDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GramDouble {
    return new GramDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GramDouble {
    return new GramDouble().fromJsonString(jsonString, options);
  }

  static equals(a: GramDouble | PlainMessage<GramDouble> | undefined, b: GramDouble | PlainMessage<GramDouble> | undefined): boolean {
    return proto3.util.equals(GramDouble, a, b);
  }
}

/**
 * @generated from message uom.v1.MilligramDouble
 */
export class MilligramDouble extends Message<MilligramDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<MilligramDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.MilligramDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MilligramDouble {
    return new MilligramDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MilligramDouble {
    return new MilligramDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MilligramDouble {
    return new MilligramDouble().fromJsonString(jsonString, options);
  }

  static equals(a: MilligramDouble | PlainMessage<MilligramDouble> | undefined, b: MilligramDouble | PlainMessage<MilligramDouble> | undefined): boolean {
    return proto3.util.equals(MilligramDouble, a, b);
  }
}

/**
 * @generated from message uom.v1.PoundDouble
 */
export class PoundDouble extends Message<PoundDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<PoundDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.PoundDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoundDouble {
    return new PoundDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoundDouble {
    return new PoundDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoundDouble {
    return new PoundDouble().fromJsonString(jsonString, options);
  }

  static equals(a: PoundDouble | PlainMessage<PoundDouble> | undefined, b: PoundDouble | PlainMessage<PoundDouble> | undefined): boolean {
    return proto3.util.equals(PoundDouble, a, b);
  }
}

/**
 * @generated from message uom.v1.OunceDouble
 */
export class OunceDouble extends Message<OunceDouble> {
  /**
   * @generated from field: double value = 1;
   */
  value = 0;

  constructor(data?: PartialMessage<OunceDouble>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uom.v1.OunceDouble";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OunceDouble {
    return new OunceDouble().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OunceDouble {
    return new OunceDouble().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OunceDouble {
    return new OunceDouble().fromJsonString(jsonString, options);
  }

  static equals(a: OunceDouble | PlainMessage<OunceDouble> | undefined, b: OunceDouble | PlainMessage<OunceDouble> | undefined): boolean {
    return proto3.util.equals(OunceDouble, a, b);
  }
}

